.container {
  width: 100vh;
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vw);
  scroll-snap-type: y mandatory;
}
::-webkit-scrollbar {
  display:none;
}
.App {
  display: flex;
  text-align: center;
  width: 400vw;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
}
.App-header{
  scroll-snap-align: start;
  width: 100vw
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  padding: 0;
  margin: 0;
}

.App-header > p {
  font-weight: bolder;
  font-size: 1.3em;
  margin: 1.1rem 0 0 0 ;
  padding: 0;
}
@media (prefers-reduced-motion: no-preference) {
 
}

.App-header {
  background-color: #1F1F1F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  width: 100vw;
}

